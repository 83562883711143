import * as supabase from "@supabase/supabase-js";
import { Move } from "chess.js";

import { Database, Json } from "../../database";
import * as jsonAPI from "../../functions/lib/json-api";
import { setUser } from "./error-reporting";

const SUPABASE_URL = process.env.SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.SUPABASE_ANON_KEY;

if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
  throw new Error("Missing Supabase environment variables");
}

const client = supabase.createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY);

client.auth.onAuthStateChange((event, session) => {
  consoleDebug("auth.onAuthStateChange", { event, session });
  if (event === "SIGNED_IN") {
    // Set the user in Bugsnag
    setUser(session?.user?.id);
  } else if (event === "SIGNED_OUT") {
    setUser(undefined);
  }
});

export async function getCurrentUser() {
  const { data, error } = await client.auth.getSession();

  logResponse("getCurrentUser", { data, error });
  return data.session?.user;
}

export async function signOut() {
  const { error } = await client.auth.signOut();
  logResponse("signOut", { error });
  return { error };
}

export async function sendSignInLink(email: string) {
  const { data, error } = await client.auth.signInWithOtp({
    email,
    options: {
      shouldCreateUser: false,
      emailRedirectTo: window.location.toString(),
    },
  });
  logResponse("sendSignInLink", { data, error });
  return { data, error };
}

export async function registerCustomer(checkoutSessionId: string) {
  const { data, error } = await jsonFetch<jsonAPI.RegistrationResponse>('/api/registration', 'POST', { checkoutSessionId });
  consoleDebug("registerCustomer", data);
  return { error, signinUrl: data?.signinUrl };
}

// Update the user's email address
export async function updateUserEmail(newEmail: string, userData: Record<string, Json>) {
  const { data, error } = await client.auth.updateUser(
    { email: newEmail, data: userData },
    { emailRedirectTo: window.location.toString() }
  );
  logResponse("updateUserEmail", { data, error });
  return { user: data.user, error };
}

// Update the user's details
export async function updateUser(userData: Record<string, Json>) {
  if (!await getCurrentUser()) return { user: null, error: null };

  const { data, error } = await client.auth.updateUser(
    { data: userData },
  );
  logResponse("updateUser", { data, error });
  return { user: data.user, error };
}

// Get the current user's billing information
export async function getBillingUrl() {
  if (!await getCurrentUser()) return {};

  const { data, error } = await jsonFetch<jsonAPI.StripeBillingResponse>("/api/stripe/billing");
  logResponse("getBillingUrl", { data });
  return { error, url: data?.url };
}

export async function getRandomPosition() {
  const { data } = await jsonFetch<jsonAPI.Position>("/api/random-position");
  consoleDebug("getRandomPosition", data);
  return data;
}

// Get the weekly scores using the  stored procedure
export async function getWeeklyScores() {
  if (!await getCurrentUser()) return [];

  const { data, error } = await client.rpc("weekly_vision_scores");
  logResponse("getWeeklyScores", { data, error });
  return data || [];
}

export async function getStreak(): Promise<number> {
  if (!await getCurrentUser()) return 0;

  const { data, error } = await client.rpc("vision_score_streak").single();
  logResponse("getStreak", { data, error });
  if (error) return 0;

  const { streak } = data;
  return streak;
}

// Get the achievements for the current user
export async function getAchievements() {
  const { data } = await jsonFetch<jsonAPI.AchievementsResponse>("/api/achievements");
  consoleDebug("getAchievements", data);
  return data;
}

export async function getTrend() {
  if (!await getCurrentUser()) return [];

  const { data, error } = await client.rpc("vision_accuracy_trend");
  logResponse("getTrend", { data, error });
  return data || [];
}

// Submit the moves for score, analysis, and achievements
export async function submitMoves(
  positionId: string,
  moveType: "check" | "capture",
  fen: string,
  moves: Array<Move>,
  elapsed: number,
) {
  const { data } = await jsonFetch<jsonAPI.PositionResponse, jsonAPI.PositionRequest>(
    "/api/position",
    "POST",
    {
      positionId,
      moveType,
      fen,
      moves: moves.map(m => m.san),
      elapsed,
    }
  );
  logResponse("submitMoves", { data });
  return data;
}

function logResponse(
  source: string,
  { error, data }: { error?: unknown; data?: unknown },
) {
  if (error) console.error(source, error);
  consoleDebug(source, data);
}

export async function jsonFetch<Resp = Json, Req = Json>(
  path: string,
  method: string = "GET",
  body?: Req,
  query?: Record<string, string>
) {
  // Get the current user's session token
  const { data: { session } } = await client.auth.getSession();
  const headers = { "Content-Type": "application/json" } as Record<string, string>;
  if (session) headers["Authorization"] = `Bearer ${session.access_token}`;

  const requestInit: RequestInit = { method, headers: new Headers(headers) };
  if (body) requestInit.body = JSON.stringify(body);
  const url = query ? path + "?" + new URLSearchParams(query) : path;
  const response = await fetch(url, requestInit);
  if (!response.ok) {
    console.error(response.statusText);
    // If the response is 422 (Unprocessable Entity) then the error is for the user
    return response.status === 422
      ? { error: await response.text() }
      : {}
  }
  const data = await response.json() as Resp;
  return { data };
}

const consoleDebug = process.env.NODE_ENV === "development" ? console.debug : () => {};
